<template>
   <div id="app-adprofex-container" style="width: 0 !important; height: 0 !important; position: absolute; z-index: -1; bottom: -10000px">
        <div id="7p5kca5d4j5"></div>
   </div>
</template>


<script>

import { mapActions } from 'vuex';
export default {
   created: function(){
       const vueContext = this
       const a = document.createElement('script')
       a.src = 'https://hfnuqljjnt.com/script.js'
       a.onload = function(){
        vueContext.containerLoaded()
       }
       document.head.appendChild(a)
   },
   methods: {
       ...mapActions(['FETCH_ADPROFEX']),
       containerLoaded: function(){
            !(function(w,m){(w[m]||(w[m]=[]))&&w[m].push({id:'7p5kca5d4j5',block:'143398',site_id:'60989'});})(window, 'mtzBlocks')

            const container = document.getElementById('app-adprofex-container')
            const linkNodes = container.getElementsByTagName('a')
            const vueContext = this
            const itemsArray = []
            const checkByInterval = window.setInterval(function(){
                if (linkNodes.length){
                    clearInterval(checkByInterval)
                    for (let i=0; i<linkNodes.length; i++){
                        //картинка идет бэкграундом, выдергиваем
                        const imgSrc = linkNodes[i].childNodes[0].childNodes[0].src
                        const title = linkNodes[i].childNodes[1].childNodes[0].innerText
                        const newItem = {
                            url: linkNodes[i].href,
                            img: imgSrc,
                            title: title,
                        }
                        itemsArray.push(newItem)
                    }
                    vueContext.FETCH_ADPROFEX(itemsArray)
                }
            }, 50)
       }
   }
}
</script>
