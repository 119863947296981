<template>
    <div>
        <GetContent 
            v-bind:dataFeed="dataFeed" 
            v-bind:unrefreshable="unrefreshable"                        
            v-on:contentGetted="contentGetted" 
            v-on:contentReset="contentReset"
        ></GetContent>                
        <b-alert 
            v-if="this.SCREEN_SIZE>1"
            show 
            class="position-fixed rounded-0 py-2 m-0" 
            :style="tickerStyle"
            variant="primary"
        >
            <div class="app-marquee">
                <TickerBlockItem v-for="(item, index) in blockItems" 
                    v-bind:key="index"
                    v-bind:item="item"       
                    v-bind:goalName="goalName" 
                    v-on:itemClick="itemClick"  
                >
                </TickerBlockItem>
            </div>
        </b-alert>
    </div>
</template>

<script>
import utils from '../../functions/functions'
import TickerBlockItem from '../integrations/TickerBlockItem.vue'
import GetContent from '../general/GetContent.vue'
import { BAlert } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        GetContent,
        BAlert,
        TickerBlockItem
    },
    data(){
        return {
            blockItems: [],
        }
    },
    props: ['limit', 'goalName', 'dataFeed', 'unrefreshable'],
    computed: {
        ...mapGetters(['REFRESHING', 'SCREEN_SIZE', 'PLATFORM']),
        tickerStyle: function(){
            const styleBase = 'left: 0; right: 0; '
            const position = (this.PLATFORM === 'desktop') ? 'bottom: 0; ' : 'top: 0; ' 
            const zIndex = (this.PLATFORM === 'desktop') ? 'z-index: 1021;' : 'z-index: 1018'
            return styleBase+position+zIndex
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },        
    },
    methods: {
        itemClick: function(){
            const vueContext = this
            if (this.goalName){
                utils.safeGoal(this.goalName, vueContext)
                utils.safeGoal('GoLink', vueContext)
            }
        },        
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)
            const limit = (this.limit) ? this.limit : arr.length
            this.blockItems = shuffled.slice(0, limit)
        },
        contentGetted: function(arr){
            this.arrayClone(arr)
        },        
        contentReset: function(){
            this.blockItems = []
        },
    },
}
</script>

<style lang="sass">
@keyframes scroll-marquee
    to
        transform: translateX(-100%)
.app-marquee    
    overflow: hidden
    white-space: nowrap
    display: inline-block
    animation: scroll-marquee 1400s linear infinite
    &:hover
        animation-play-state: paused
</style>