export default {
    state: {
        browser: '',
        os: '',
        platform: ''
    },
    actions: {
        SET_BROWSER(context, browser){
            context.commit('UPDATE_BROWSER', browser)
        },
        SET_OS(context, os){
            context.commit('UPDATE_OS', os)
        },
        SET_PLATFORM(context, platform){
            context.commit('UPDATE_PLATFORM', platform)
        },        
    },
    mutations: {
        UPDATE_BROWSER(state, browser){
            state.browser = browser
        },
        UPDATE_OS(state, os){
            state.os = os
        },
        UPDATE_PLATFORM(state, platform){
            state.platform = platform
        }
    },
    getters: {
        BROWSER(state){
            return state.browser
        },
        OS(state){
            return state.os
        },
        PLATFORM(state){
            return state.platform
        }
    }
}