<template>
    <div>
        <GetContent 
            v-bind:dataFeed="dataFeed" 
            v-on:contentGetted="contentGetted" 
            v-on:contentReset="contentReset" 
            v-bind:unrefreshable="unrefreshable"                        
        ></GetContent>                
        <div v-if="SCREEN_SIZE >= 3">
            <ToastBlockItem v-for="(item, index) in blockItems"
                v-bind:key="index"
                v-bind:index="index"
                v-bind:idBase="idBase"            
                v-bind:item="item"
                v-bind:goalName="goalName"
                v-bind:siteContent="siteContent"
                v-on:itemsMounted="itemsMounted"
            ></ToastBlockItem>
        </div>
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapGetters } from 'vuex'
import GetContent from '../general/GetContent.vue'
import ToastBlockItem from './ToastBlockItem.vue'
import {BToast} from 'bootstrap-vue'
export default {
    components: {
        GetContent,
        BToast,
        ToastBlockItem
    },
    data(){
        return {
            idBase: 'toast-',
            blockItems: []
        }
    },
    props: ['dataFeed', 'limit', 'goalName', 'timeInterval', 'unrefreshable'],
    computed: {
        ...mapGetters(['SCREEN_SIZE']),
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },        
    },
    methods: {
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)            
            this.blockItems = shuffled.slice(0, this.limit)
        },
        contentGetted: function(arr){
            this.arrayClone(arr)
        },  
        contentReset: function(){
            this.blockItems = []
        },                    
        makeToast: function(){
            let vueContext = this
            let counter = utils.closureIncrement()
            let pushing = window.setInterval(function(){
                let pushNum = counter()
                const toastId = vueContext.idBase+pushNum
                vueContext.$bvToast.show(toastId)
                if (pushNum===vueContext.limit){
                    clearInterval(pushing)
                }
            }, vueContext.timeInterval)        
        },
        itemsMounted: function(payload){
            if (payload === this.blockItems.length-1){
                this.makeToast()
            }
        },
    },
}
</script>
