<template>
    <div>
        <div class="app-error-wrap position-fixed neterror" style="font-family: 'Segoe UI', Tahoma, sans-serif; font-size: 75%" id="app-error-screen">
            <div id="main-frame-error" class="interstitial-wrapper">
            <div id="main-content">
            <div class="icon icon-generic"></div>
            <div id="main-message">
                <h1 jstcache="0">
                <span>{{problemScreen.header}}</span>
                <a class="hidden"></a>
                </h1>
                <p>{{ problemScreen.cause }}</p>
                <!--The suggestion list and error code are normally presented inline,
                in which case error-information-popup-* divs have no effect. When
                error-information-popup-container has the use-popup-container class, this
                information is provided in a popup instead.-->
                <div id="error-information-popup-container">
                <div id="error-information-popup">
                    <div id="error-information-popup-box">
                    <div id="error-information-popup-content">
                        <div id="suggestions-list">
                        <p style="line-height: 0.8rem;">&nbsp;</p>
                        <ul class="single-suggestion">
                            <li>
                              <!-- Для безопасного завершения загрузки вам необходимо установить <a href="#" id="diagnose-link">Антивирусный фильтр</a>. -->
                              <p>{{ problemScreen.solutionText }}
                              <a v-if="problemScreen.solutionLink!=''" href="#" id="ErrorscreenLink" class="app-install-trigger">{{ problemScreen.solutionLink }}</a></p>
                            </li>
                        </ul>
                        </div>
                        <div class="error-code" jscontent="errorCode" jstcache="17">{{ problemScreen.code }}</div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div id="buttons" class="app-button-wrap nav-wrapper suggested-left" jstcache="0">
              <div id="control-buttons" jstcache="0">
                <button id="ErrorscreenButton" class="blue-button text-button app-install-trigger">Перезагрузить</button>
              </div>
            </div>
          </div>    
        </div>
    </div>
</template>


<script>
export default {
  data(){
    return {
    }
  },
  props: {
    problemScreen: {
      type: Object,
      required: true
    },
  },  
  computed: {},
  methods: {},
  updated: function(){},  
  mounted: function(){}
}
</script>

<style lang="sass" scoped>
button
  font-size: 1.26em
</style>

<style lang="sass">
.app-button-wrap
  opacity: 1
  transition: opacity 0.3s ease-in 0.3s
</style>