<template>
    <div class="app-rounded-block">
        <b-link 
            :href="linkHref" 
            :to="linkTo" 
            :target="target" 
            :class="linkClass" 
            @click="itemClick"
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"            
        >
            <div class="app-rounded-block__image position-relative rounded-circle overflow-hidden d-flex justify-content-center align-items-center mr-3 flex-shrink-0">
                <div class="position-absolute" style="top:0;bottom:0;left:0;right:0;">
                    <b-skeleton height="100%" width="100%"></b-skeleton>
                </div>
                <b-img-lazy :src="item.img" class="position-relative"></b-img-lazy>
            </div>
            <span class="font-weight-bold">
                {{ item.title }}
            </span>
        </b-link>
        <hr v-if="hrShow" class="my-2">
    </div>
</template>

<script>
import { BLink, BSkeleton, BImgLazy } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        BLink,
        BSkeleton,
        BImgLazy
    },
    data(){
        return {
            itemHover: false,
        }
    },
    props: ['item', 'siteContent', 'maxIndex', 'currentIndex'],
    computed: {
        ...mapGetters(['SCREEN_SIZE', 'PLATFORM']),
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (!this.siteContent) ? this.item.url : null
        },
        linkClass: function(){
            const classBase = 'd-flex align-items-center text-decoration-none text-uppercase'
            const textColor = (this.itemHover) ? 'text-primary' : 'text-black'
            return `${classBase} ${textColor}`
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        hrShow: function(){
            return (this.SCREEN_SIZE === 1 && (this.currentIndex != this.maxIndex - 1))
        }
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick')
        },
        mouseEnter: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? true : this.itemHover
        },
        mouseLeave: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? false : this.itemHover
        },        
    }
}
</script>

<style lang="sass">
.app-rounded-block__image
    width: 100px
    height: 100px
.app-rounded-block
    width: 100%
@media (min-width: 768px)        
    .app-rounded-block
        width: calc(50% - 16px)
</style>

<style lang="sass" scoped>
span
    font-weight: 500
img
    height: 100%
    width: auto    
</style>