<template>
    <div>
        <GetContent 
            v-bind:dataFeed="dataFeed" 
            v-bind:unrefreshable="unrefreshable"            
            v-on:contentGetted="contentGetted" 
            v-on:contentReset="contentReset"
        ></GetContent>                
        <div v-if="contentCeady" :class="wrapClassName+' app-block-append'">
            <TextBlockItem v-for="(item, index) in blockItems"
                v-bind:key = index
                v-bind:currentIndex = index
                v-bind:siteContent="siteContent"
                v-bind:maxIndex = limit
                v-bind:item = item
                v-bind:icon="icon"
                v-bind:hoverable="hoverable"
                v-bind:fontWeight="fontWeight"
                v-bind:borderColor="borderColor"
                v-bind:uppercase="uppercase"
                v-bind:tooltip="tooltip"
                v-on:itemClick="itemClick"                        
            ></TextBlockItem>                 
        </div>

        <div v-else :class="wrapClassName+' app-preloader-append'">
            <TextBlockPreloader v-for="(item, index) in preloaderItems"
                v-bind:key="index"
            ></TextBlockPreloader>
        </div>        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '../../functions/functions'
import TextBlockItem from './TextBlockItem'
import TextBlockPreloader from './TextBlockPreloader'
import GetContent from '../general/GetContent.vue'

export default {
    components: {
        GetContent,
        TextBlockItem,
        TextBlockPreloader
    },
    data(){
        return{
            blockItems: [],
            //будем вычислять в зависимости от limit
            preloaderItems: [],
            wrapClassName: 'd-flex flex-column',
        }
    },    
    props: ['limit', 'goalName', 'dataFeed', 'icon', 'hoverable', 'fontWeight', 'borderColor', 'uppercase', 'tooltip', 'unrefreshable'],    
    computed: {
        ...mapGetters(['REFRESHING']),
        contentCeady: function(){
            // return (this.blockItems.length && !this.REFRESHING) ? true : false
            return (this.blockItems.length) ? true : false
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },        
    },
    methods: {
        itemClick: function(){
            const vueContext = this
            if (this.goalName){
                utils.safeGoal(this.goalName, vueContext)
                utils.safeGoal('GoLink', vueContext)
            }
        },
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)            
            this.blockItems = shuffled.slice(0, this.limit)
        },
        contentGetted: function(arr){
            this.arrayClone(arr)
        },   
        contentReset: function(){
            this.blockItems = []
        },                        
    },
    created: function(){
        for (let i=0; i<this.limit; i++){
            this.preloaderItems.push('')
        }
    },
}
</script>
