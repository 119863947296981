<template>
     <!-- class="d-none" -->
    <div id="app-lucky-container" style="width: 0 !important; height: 0 !important; position: absolute; z-index: -1; top: -10000%">
        <div data-la-block="77c55405-da40-4496-b915-70fd688fd88f"></div>
    </div>
</template>


<script>
//FETCH_LUCKY
import { mapActions } from 'vuex';
export default {
    created: function(){
        const vueContext = this
        const a = document.createElement('script')
        a.src = 'https://cvnqiv.com/e8wl71192/vlipm030yh8q/786uvq/687kpy7a83.php'
        a.onload = function(){
            vueContext.containerLoaded()
        }
        document.head.appendChild(a)
    },
    methods: {
        ...mapActions(['FETCH_LUCKY']),
        containerLoaded: function(){
            const container = document.getElementById('app-lucky-container')
            const linkNodes = container.getElementsByTagName('a')
            const vueContext = this
            const itemsArray = []
            const checkByInterval = window.setInterval(function(){
                if (linkNodes.length){
                    clearInterval(checkByInterval)
                    for (let i=0; i<linkNodes.length; i++){
                        //картинка идет бэкграундом, выдергиваем
                        const imgDiv = linkNodes[i].childNodes[0].childNodes[0].childNodes[2].childNodes[0].outerHTML
                        const imgMatches = imgDiv.match(/background-image:url\('(.+?)'\) /)
                        const title = linkNodes[i].childNodes[0].childNodes[2].innerText

                        const newItem = {
                            url: linkNodes[i].href,
                            img: imgMatches[1],
                            title: title,
                        }
                        itemsArray.push(newItem)
                    }
                    vueContext.FETCH_LUCKY(itemsArray)
                }
            }, 50)
        }
    }
}
</script>
