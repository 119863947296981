<template>
    <b-link
        :class="classLink" 
        :style="styleParent"
        :to="linkTo"
        :href="linkHref"
        :target="target"
        @click="itemClick"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"        
    >
        <b-aspect :aspect="aspect" :class="classAspect">
            <div :class="classImageWrap">
                <b-img-lazy :src="imageSrc" :style="styleImg"></b-img-lazy>
            </div>
        </b-aspect>
        <div :class="classTextWrap" style="top:0;left:0;right:0;bottom:0;">
            <div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
            </div>
            <div :class="classTitle" :style="styleTitle">
                {{ item.title }}
            </div>
            <div v-if="!adsMarker" class="align-self-start">
                <div v-if="!noName && item.name" class="app-slide-name position-relative rounded-pill text-white px-3 py-0 bg-dark">
                    {{ item.name }}
                </div>
            </div>
            <div v-else>
                <b-badge pill variant="success" class="position-relative">Читать с рекламой</b-badge>
            </div>
        </div>
    </b-link>
</template>


<script>
import {FastAverageColor} from 'fast-average-color'
import {BAspect, BImgLazy, BLink, BBadge} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import utils from '../../functions/functions'
export default {
    components: {
        BAspect,
        BImgLazy,
        BLink,
        BBadge
    },
    data(){
        return{
            mainColorRgb: '255, 255, 255',
            classAspect: 'position-relative',
            classImageWrap: 'app-slide__image-wrap d-flex justify-content-center align-items-start position-absolute',
            classTextWrap: 'position-absolute d-flex flex-column justify-content-end align-itemc-start pb-2 px-2',
            itemHover: false,
        }
    },
    props: ['item', 'index', 'siteContent', 'uniqId', 'noName'],
    computed: {
        ...mapGetters(['ACTIVE_NOW']),
        classLink: function(){
            return `app-slide app-slide-${this.uniqId} d-block position-relative rounded overflow-hidden pb-5 flex-shrink-0 mr-3`
        },
        classTitle: function(){
            const classBase = 'app-slide-title position-relative mb-2 font-weight-bold ' //text-white font-weight-extra-bold
            const textModificator = (this.mainColorRgb === '255, 255, 255') ? '' : 'text-white'
            return classBase+textModificator
        }, 
        styleTitle: function(){
            return (this.mainColorRgb === '255, 255, 255') ? 'color: black; text-shadow: rgb(255, 255, 255) 0 0px 4px, rgb(255, 255, 255) 0 0px 4px, rgb(255, 255, 255) 0 0px 4px;' : 'text-shadow: 0 0 4px #000000;'
        },
        imageSrc: function(){
            return this.item.img.replaceAll('100_100', '400_400')
        },
        styleParent: function(){
            return `background:rgb(${this.mainColorRgb})`
        },        
        styleColorOverlay: function(){
            const radialGradient = `background:radial-gradient(100% 100% at 50% 0px, transparent 50%, rgb(${this.mainColorRgb}) 100%) rgba(0, 0, 0, 0.2)`
            // return (this.mainColorRgb) ? radialGradient : null
            return radialGradient
        },
        styleImg: function(){
            return (this.itemHover) ? 'filter:brightness(1.2)' : null
        },        
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : this.item.url
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        aspect: function(){
            //return (this.siteContent) ? '16:9' : '1:1'
            return '1:1'
        },
        adsMarker: function(){
            const vueContext = this
            return (utils.isRotator(vueContext) && this.item.url.match(/smi2\./i))
        }                
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        },
        mouseEnter: function(){
            this.itemHover = true
        },
        mouseLeave: function(){
            this.itemHover = false
        },        
    },
    created: function(){
        if (!this.item.rgb){
            const imgPath = (this.siteContent) ? this.item.imgMicro : this.item.img
            const fac = new FastAverageColor()
            fac.getColorAsync(imgPath, {
                mode: 'speed'
            })
                .then(color => {
                    // this.mainColorHex = color.hex
                    const colorString = color.rgb
                    const match = colorString.match(/([\d]+),([\d]+),([\d]+)/i)
                    this.mainColorRgb = `${match[1]},${match[2]},${match[3]}`
                })
                .catch(e => {
                    console.log('color define brocken')
                    // console.log(e);
                }
            )
        }else{
            this.mainColorRgb = this.item.rgb
        }
    },
    beforeMount: function(){
    },
    mounted: function(){
        this.$emit('itemsMounted', this.index)
    },
    watch: {
    }
}
</script>

<style lang="sass">
.app-slide__image-wrap
    top: 0
    bottom: 0
    left: 0
    right: 0
.app-slide
    width: 240px
    // height: 300px ////////////////////
.app-slide-name
    font-size: 0.7rem
    opacity: 0.5
.app-slide-overlay
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: transparent
    transition: all 1.15s
.app-slide-title    
    font-size: 1.2rem
    transition: all 0.15s
    line-height: 1.4
    // text-shadow: 0 0 4px #000000
</style>

<style lang="sass" scoped>
img
    width: auto
    height: 100%
    transition: all 0.2s
</style>