const scrollHeightCookieMatch = document.cookie.match(/scrollY=([\d]+)/i)
export default {
    state: {
        ready: false,
        time: undefined,
        subscribe: undefined, //false
        installAbility: undefined,
        isStandalone: undefined,
    },
    actions: {
        STANDALONE_CHECK(context){
            context.commit('STANDALONE_UPDATE')
        },
        INSTALLABILITY_CHECK(context){
            context.commit('INSTALLABILITY_UPDATE')
        },
        FLOW_READY_CHECK(context, value){
            context.commit('FLOW_READY_UPDATE', value)
        },
        SESSION_START(context){
            const timeSec = parseInt(new Date().getTime())
            context.commit('TIME_UPDATE', timeSec)
        },
        PROMPT_ACCEPT(context){
            localStorage.setItem('subscribe', 1)
            context.commit('SUBSCRIBE_UPDATE', true)
        },
        PRPOMPT_DECLINE(context){
            context.commit('SUBSCRIBE_UPDATE', false)
        },
        SUBSCRIBE_CHECK(context){
            context.commit('SUBSCRIBE_UPDATE')
        }
    },
    mutations: {
        STANDALONE_UPDATE(state){
            state.isStandalone = !!window.matchMedia('(display-mode: standalone)').matches
        },
        INSTALLABILITY_UPDATE(state){
            state.installAbility = (window.BeforeInstallPromptEvent) ? true : false
        },
        FLOW_READY_UPDATE(state, value){
            state.ready = value
        },
        TIME_UPDATE(state, time){
            state.time = time
        },
        SUBSCRIBE_UPDATE(state, subscribe){
            // const subscribe = (localStorage.getItem('subscribe')==='1') ? true : state.subscribe
            state.subscribe = subscribe
        }        
    },
    getters: {
        STANDALONE(state){
            return state.isStandalone
        },
        INSTALLABILITY(state){
            return state.installAbility
        },
        FLOW_READY(state){
            return state.ready
        },
        START_TIME(state){
            return state.time
        },
        SUBSCRIBE(state){
            return state.subscribe
        }
    }
}