<template>
    <div>
        <GetContent 
            v-bind:dataFeed="dataFeed" 
            v-bind:unrefreshable="unrefreshable"            
            v-on:contentGetted="contentGetted" 
            v-on:contentReset="contentReset"
        ></GetContent>                
        <div v-if="contentReady" :class="wrapClassName+' app-block-append'">
            <SimpleBlockItem v-for="(item, index) in blockItems"
                v-bind:key = index
                v-bind:item = item
                v-bind:siteContent = "siteContent"
                v-on:itemClick="itemClick"
            >
            </SimpleBlockItem>
            <!-- маркер прокрутки -->
            <div v-if="infinity" class="w-100 d-block" style="height: 1px;" v-b-visible="itemsIncrease"></div>
        </div>
        <div v-else :class="wrapClassName+' app-preloader-append'">
            <SimpleBlockPreloader v-for="(item, index) in preloaderItems"
                v-bind:key="index"
                v-bind:siteContent="siteContent"
            ></SimpleBlockPreloader>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '../../functions/functions'
import SimpleBlockItem from './SimpleBlockItem'
import SimpleBlockPreloader from './SimpleBlockPreloader'
import GetContent from '../general/GetContent.vue'

export default {
    components: {
        GetContent,
        SimpleBlockItem,
        SimpleBlockPreloader
    },      
    data(){
        return{
            blockItems: [],
            blockItemsLocked: [],
            //обрезка верхнего блока
            trimmedLength: utils.itemsCount(59),
            wrapClassName: 'd-flex flex-column',
            preloaderItems: []
        }
    },
    props: ['infinity', 'autoCount', 'limit', 'goalName', 'dataFeed', 'unrefreshable', 'cutPoints'],
    computed: {
        ...mapGetters(['REFRESHING']),
        contentReady: function(){
            // return (this.blockItems.length && !this.REFRESHING) ? true : false
            return (this.blockItems.length) ? true : false
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },        
    },
    methods: {
        itemsIncrease: function(isVisible){
            if (isVisible && this.blockItemsLocked.length){
                const newArr = utils.shuffleArray(this.blockItemsLocked)
                const limit = (this.limit) ? this.limit : newArr.length
                for (let i=0; i < limit; i++){
                    this.blockItems.push(newArr[i])
                }
            }
        },
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = (this.dataFeed === 'site') ? arr :  utils.shuffleArray(arr)
            let limit = arr.length
            if (this.autoCount){
                limit = this.trimmedLength
            }else if (this.limit){
                limit = this.limit
            }
            const trimStart = (this.cutPoints) ? this.cutPoints[0] : 0
            const trimFinish = (this.cutPoints) ? this.cutPoints[1] : limit
            this.blockItems = shuffled.slice(trimStart, trimFinish)
            this.blockItemsLocked = shuffled.slice(trimStart, trimFinish)
        },
        contentGetted: function(arr){
            this.arrayClone(arr)
        },      
        contentReset: function(){
            this.blockItems = []
        },           
        itemClick: function(){
            const vueContext = this
            if (this.goalName){
                utils.safeGoal(this.goalName, vueContext)
                utils.safeGoal('GoLink', vueContext)
            }
        }
    },
    created: function(){
        const max = (this.limit) ? this.limit : 48
        for (let i=0; i<max; i++){
            this.preloaderItems.push('')
        }
    },
}
</script>
