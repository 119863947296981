<template>
    <div class="position-relative" id="app-nav-wrap">

        <!-- для мониторинга прокрутки -->
        <!-- 44 -->
        <i v-if="SCREEN_SIZE<2" :class="markerClass" v-b-visible.38="visibleHandler"></i>                            
        <i v-else :class="markerClass" v-b-visible.0="visibleHandler"></i>                            

        <b-navbar toggleable="lg" :class="navClass">
            <div v-if="brandShow" class="text-uppercase mt-2 mt-sm-0 d-flex" @click="newsUpdate('HeaderClick')" style="cursor: pointer;">
                <span class="mr-2 font-weight-bold text-white">
                    <!-- <b>Г</b> -->
                    Главные
                </span>
                <span class="text-secondary font-weight-bold d-flex">
                    <b class="text-info">Н</b>
                    <div>овости</div>
                </span>
            </div>

            <b-button variant="info" size="sm" :disabled="this.REFRESHING" @click="newsUpdate('ButtonClick')">
                <span class="mr-2" style="font-weight: 500;">Обновить</span>
                <b-icon icon="arrow-clockwise" style="transform: rotate(45deg)"></b-icon>                
            </b-button>

        </b-navbar>

        <!-- пустой блок-затычка -->
        <div class="d-block w-100 position-relative" :style="emptyBlock"></div>

    </div>
</template>

<script>
//SCREEN_SIZE
import { mapGetters, mapActions } from 'vuex'
import { BNavbar, BButton } from 'bootstrap-vue'
import utils from '../../functions/functions'
export default {
    components: {
        BNavbar,
        BButton
    },
    data(){
        return{
            markerVisible: true,
            offsetTop: 0,
            markerClass: 'w-100 bg-dark d-block position-relative'
        }
    },
    computed: {
        ...mapGetters(['SCREEN_SIZE', 'REFRESHING', 'ACTIVE_NOW']),
        brandShow: function(){
            return (this.markerVisible || this.SCREEN_SIZE >= 2)
        },
        navClass: function(){
            const classConst = 'd-flex justify-content-between px-2 px-sm-3 pt-0 shadow '
            const classVar = (this.markerVisible) ? 'pb-2 pt-sm-2' : 'pb-0 position-fixed'
            return classConst+classVar
        },
        emptyBlock: function(){
            const transition = 'transition: height 0.3s; '
            const height = `height:${this.offsetTop}px`
            // const divCLass = `transition: height 0.3s; height:${this.offsetTop}px`
            return (this.markerVisible) ? transition : transition+height
        },
        stickyStartPoint: function(){
            return (this.SCREEN_SIZE < 2) ? 44 : 0
        }
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'FETCH_INFOX']),
        visibleHandler: function(isVisible){
            this.markerVisible = isVisible
        },
        newsUpdate: function(goalName){
            const vueContext = this
            utils.safeGoal(goalName, vueContext)
            if (this.ACTIVE_NOW === 'infox'){
                this.FETCH_SMI24(vueContext)
            } else if (this.ACTIVE_NOW === 'smi24'){
                this.FETCH_INFOX(vueContext)
            }
        },
        test: function(){
            return 44
        }
    },
    mounted: function(){
        this.offsetTop = document.getElementById('app-nav-wrap').clientHeight
        //онресайз
        const vueContext = this
        window.addEventListener('resize', function(){
            vueContext.offsetTop = document.getElementById('app-nav-wrap').clientHeight
        })   
    },
}
</script>






<style lang="sass" scoped>
a
    font-size: 1rem 
nav
    transition: padding-bottom 0.4s, padding-top 0.4s
    z-index: 1  
    top: 0
    left: 0
    right: 0  
    background: #232323
i
    z-index: 2
    height: 0px
button
    background-color: #138496  
    border-color: #138496    
    &:hover
        background-color: #17a2b8
        border-color: #17a2b8
</style>