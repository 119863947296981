<template>
    <!-- <li class="app-ticker-list__item flex-shrink-0 mr-5"> -->
       <b-link 
           :href="linkHref"
           :to="linkTo"
           :target="target"
           :class="linkClass"
           @click="itemClick"
           @mouseenter="mouseEnter"
           @mouseleave="mouseLeave"                   
       >
        <b-icon icon="newspaper" class="mr-2"></b-icon>
        <span>
            {{item.title}}
        </span>
       </b-link>
   <!-- </li> -->
</template>

<script>
// import utils from '../../functions/functions'
import { BLink } from 'bootstrap-vue'
export default {
    components: {
        BLink
    },
    data(){
        return {
            itemHover: false
        }
    },
    props: ['item', 'siteContent'],
    computed: {
        linkHref: function(){
            return (this.siteContent) ? null : this.item.url
        },
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        linkClass: function(){
            const classBase = 'mr-5'
            const textColor = (this.itemHover) ? 'text-primary' : 'text-dark'
            return `${classBase} ${textColor}`
        }
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        },
        mouseEnter: function(){
            this.itemHover = true
        },
        mouseLeave: function(){
            this.itemHover = false
        }        
    }
}
</script>


<style lang="sass" scoped>

@import '../../sass/main'
a
    // color: darken($cyan, 30%)
    font-weight: 500
span
    // font-weight: 600
    // color: black !important    
</style>