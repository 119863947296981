<template>
    <div class="d-flex justify-content-center w-100 my-3">
        <GetContent 
            v-bind:dataFeed="dataFeed" 
            v-bind:unrefreshable="unrefreshable"
            v-on:contentGetted="contentGetted" 
            v-on:contentReset="contentReset"
        ></GetContent>
        <!-- contentReady -->
            <!-- <transition name="append"> -->
                <!-- :style="appendBlock" -->
                <b-link v-if="contentReady"
                    :href="linkHref" 
                    :to="linkTo" 
                    :target="target" 
                    @click="itemClick"
                    class="app-block-append d-flex justify-content-end align-items-stretch text-decoration-none rounded border bg-light p-2"
                >
                    <div class="d-flex flex-column justify-content-between">
                        <div class="d-flex flex-column justify-content-between flex-grow-1">
                            <h5 class="text-dark">
                                {{ item.title }}
                            </h5>
                            <p class="d-none d-md-block text-secondary font-weight-light font-italic">
                                Читайте больше подробностей на сайте {{ item.name }}
                            </p>
                        </div>
                        <div class="d-none d-md-flex border-top pt-2">
                            <b-button pill size="sm" variant="outline-danger" class="font-weight-bold px-3 mr-3">Перейти к новости</b-button>
                            <b-button pill size="sm" variant="outline-primary" class="font-weight-bold px-3 ">Читать на сайте</b-button>
                        </div>    
                    </div>
                    <b-aspect :aspect="aspect" class="app-skeleton-wrap overflow-hidden rounded flex-shrink-0 position-relative ml-3 ml-xl-5 align-self-center position-relative">
                        <b-skeleton height="100%" width="100%"></b-skeleton>
                        <div class="position-absolute d-flex justify-content-center align-items-center" style="top:0; bottom:0; left:0; right:0;">
                            <b-img-lazy :src="imageSrc"></b-img-lazy>
                        </div>            
                    </b-aspect>
                </b-link>        
                <!-- :style="appendPreloader" -->
                <div v-else class="app-preloader-append app-jumbtron-skeleton rounded overflow-hidden w-100">
                    <b-skeleton width="100%" height="100%"></b-skeleton>
                </div>                
            <!-- </transition>         -->

            <!-- <transition name="fade"> -->
                <!-- <div v-if="!contentReady" class="app-jumbtron-skeleton rounded overflow-hidden w-100" style="min-width: 100%;">
                    <b-skeleton width="100%" height="100%"></b-skeleton>
                </div> -->
            <!-- </transition> -->

    </div>
</template>

<script>
import { BLink, BSkeleton, BAspect, BButton, BImgLazy } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import GetContent from '../general/GetContent.vue'
import utils from '../../functions/functions'
export default {
    components:{
        GetContent,
        BLink, 
        BSkeleton, 
        BAspect, 
        BButton, 
        BImgLazy
    },
    data(){
        return {
            item: undefined,
            siteContent: false,
            appendBlock: 'opacity: 0;',
            appendPreloader: 'opacity: 0; min-width: 100%;',
        }
    },
    props: ['dataFeed', 'goalName', 'unrefreshable'],
    computed: {
        ...mapGetters(['REFRESHING']),
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (!this.siteContent) ? this.item.url : null
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        sourceName: function(){
            return (this.item.name) ? this.item.name : 'newsonline.press'
        },
        aspect: function(){
            let aspect = '1:1'
            if (this.siteContent){
                aspect = '16:9'
            }else if (this.item && this.item.url.match(/smi2\./i)){
                aspect = '4:3'
            }
            return aspect
        },
        imageSrc: function(){
            return this.item.img.replaceAll('100_100', '400_400')
        },
        contentReady: function(){
            // && !this.REFRESHING
            return (this.item) ? true : false
            // return false
        },
    },
    methods: {
        itemClone: function(arr){
            const newArr = utils.shuffleArray(arr)
            this.item = newArr[0]
        },
        itemClick: function(){
            const vueContext = this
            utils.safeGoal(this.goalName, vueContext)
            utils.safeGoal('GoLink', vueContext)
        },
        contentGetted: function(arr){
            this.itemClone(arr)
        },
        contentReset: function(){
            this.item = undefined
            this.appendBlock = 'opacity: 0;'
            // this.preloaderAnimate()
        },
        /*
        blockAnimate: function(){
            const vueContext = this
            window.setTimeout(function(){
                vueContext.appendBlock = null
            }, 10)                        
        },
        preloaderAnimate: function(){
            console.log('preloaderAnimate')
            const vueContext = this
            window.setTimeout(function(){
                vueContext.appendPreloader = 'min-width: 100%;'
            }, 10)                        
        },
        */        
    },
    beforeMount: function(){
        this.siteContent = (this.dataFeed === 'site') ? true : false
    },
    mounted: function(){
        //первичное появление прелоадера
        // this.preloaderAnimate()
    },
    watch: {
        contentReady: function(newVal, oldVal){
            // console.log('contentReady')
            if (newVal && !oldVal){
                const vueContext = this
                window.setTimeout(function(){
                    vueContext.appendBlock = null
                }, 10)
            }
        },
        item: function(){
            if (!this.item){
                this.appendPreloader = 'opacity:0; min-width:100%;'
            }else{
                this.appendPreloader = 'opacity:0;'
            }
        }
    }
}
</script>

<style lang="sass">

.app-skeleton-wrap
    width: 100px
.app-jumbtron-skeleton    
    height: 122px
    transition: opacity 0.2s
@media (min-width: 768px)        
    .app-skeleton-wrap
        width: 140px
    .app-jumbtron-skeleton    
        height: 191px        
@media (min-width: 992px)        
    .app-skeleton-wrap
        width: 200px
    .app-jumbtron-skeleton    
        height: 222px        
</style>

<style lang="sass" scoped>
a
    transition: all 0.2s
img
    width: 100%
    height: auto
h5
    font-size: 1.1rem
@media (min-width: 768px)    
    h5
        font-size: 1.25rem
@media (min-width: 992px)                
    h5
        font-size: 1.8rem   
//      122 191 222   
</style>