<template>
    <div class="p-5 m-5">
        {{ PLATFORM }}        
    </div>
</template>

<script>
import { mapGetters} from 'vuex'
export default {
    computed: {
        ...mapGetters(['PLATFORM'])
    },
}
</script>
