<template>
    <div class="app-slide pb-5 rounded overflow-hidden position-relative flex-shrink-0 mr-3 overflow-hidden" style="height: 300px;">
        <!-- class="position-relative" -->
        <b-aspect :aspect="aspect"></b-aspect>
        <div class="position-absolute" style="top: 0; bottom: 0; left: 0; right: 0;">
            <b-skeleton height="100%"></b-skeleton>
        </div>
    </div>
</template>


<script>
import { BAspect, BSkeleton} from 'bootstrap-vue'

export default {
    components: {
        BAspect,
        BSkeleton
    },    
    data(){
        return {
            
        }
    },
    props: ['siteContent'],
    computed: {
        aspect: function(){
            //return (this.siteContent) ? '16:9' : '1:1'
            return '16:9'
        },        
    }
}
</script>

<style lang="sass">
.app-slide-preloader
    width: 192px !important
    height: 252px !important
</style>