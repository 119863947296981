import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './sass/main.sass'

//бутстрап надо импортировать по частям 
// import utils from './functions/functions'
import { IconsPlugin } from 'bootstrap-vue'
import { VBVisible } from 'bootstrap-vue'
Vue.directive('b-visible', VBVisible)
// Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)

import { SidebarPlugin } from 'bootstrap-vue'
Vue.use(SidebarPlugin)

import OneSignalVue from 'onesignal-vue'
Vue.use(OneSignalVue)


import VueYandexMetrika from 'vue-yandex-metrika'   

if (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK){
  let metrikaId = process.env.VUE_APP_METRIKA_1
  if (document.location.href.match(/\/app/i)){
    metrikaId = process.env.VUE_APP_METRIKA_2
  }else if(document.location.href.match(/\/pwa1/i)){
    metrikaId = process.env.VUE_APP_METRIKA_4
  }else if(document.location.href.match(/\/pwa2/i)){
    metrikaId = process.env.VUE_APP_METRIKA_5
  }else if(document.location.href.match(/\/pwa3/i)){
    metrikaId = process.env.VUE_APP_METRIKA_6
  }else if(document.location.href.match(/\/pwa4/i)){
    metrikaId = process.env.VUE_APP_METRIKA_7
  }else if(document.location.href.match(/\/pwa5/i)){
    metrikaId = process.env.VUE_APP_METRIKA_8
  }else if(document.location.href.match(/\/pwa6/i)){
    metrikaId = process.env.VUE_APP_METRIKA_9
  }else if(document.location.href.match(/\/pwa7/i)){
    metrikaId = process.env.VUE_APP_METRIKA_10
  }

  //эта регулярка будет в самом конце
  else if(document.location.href.match(/\/pwa/i)){
    metrikaId = process.env.VUE_APP_METRIKA_3
  }
  Vue.use(VueYandexMetrika, {
    //id: (document.location.href.match(/app/i)) ? process.env.VUE_APP_METRIKA_2 : process.env.VUE_APP_METRIKA_1,
    id: metrikaId,
    router: router,
    env: (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK) ? 'production' : 'development',
    // env: 'production',
    options: {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true  
    }
  })
}


import './css/chrome-style.css'    

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
