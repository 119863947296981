import utils  from "../functions/functions"
export default {
    state: { 
        model0: undefined,
        model1: undefined,
        modelsActive: [
            //индексы активных моделей
        ]
    },
    actions: {
        async GET_MODEL(context, modelNum){
            const id = utils.getIdFromAddressBar()
            document.cookie = `id=${id}; path=/;`

            context.commit('MODEL_ACTIVATE', modelNum)
            const url = utils.apiModel(modelNum)
            const res = await fetch(url, {
                credentials: 'include',
                cache: 'no-store',
                mode: 'no-cors'
            })                     
            const textResponse = await res.text()
            // console.log('textResponse', textResponse)
            const result = parseInt(textResponse)
            const payloadObj = {
                modelNum: modelNum,
                result: result
            }
            context.commit('MODEL_UPDATE', payloadObj)   
        },
    },
    mutations: {
        MODEL_UPDATE(state, payloadObj){
            state[`model${payloadObj.modelNum}`] = payloadObj.result
        },
        MODEL_ACTIVATE(state, modelNum){
            state.modelsActive.push(modelNum)
        }
    },
    getters: {
        MODEL0(state){
            return state.model0
        },
        MODEL1(state){
            return state.model1
        },        
        API_INITED(state){
            let res = undefined
            if (!state.modelsActive.length){
                res = false
            }else{
                res = true
                for (let i=0; i<state.modelsActive.length; i++){
                    // const modelName = `model${state.modelsActive[i]}`
                    if (state[`model${state.modelsActive[i]}`] === undefined){
                        res = false
                        break
                    }
                }
            }
            return res
        }
    },    
}