/*
<script src="https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js" defer></script>
  window.OneSignalDeferred = window.OneSignalDeferred || [];
  OneSignalDeferred.push(async function(OneSignal) {
    await OneSignal.init({
      appId: "d8b5b0db-9c96-4de8-9075-76448d95f174",
      safari_web_id: "web.onesignal.auto.00e855ed-5f66-45b8-ad03-54b1e142944e",
      notifyButton: {
        enable: true,
      },
    });
  });
*/
export default {
  state: {
    active: false
  },
  actions: {
    INFORMER_FIRE: function(context){
      console.log('INFORMER_FIRE action')
      context.commit('INFORMER_STATE_UPDATE', true)
    },
    INFORMER_HIDE: function(context){
      console.log('INFORMER_HIDE action')
      context.commit('INFORMER_STATE_UPDATE', false)
    }
  },
  mutations: {
    INFORMER_STATE_UPDATE: function(state, val){
      console.log('INFORMER_STATE_UPDATE mutation')
      state.active = val
    }
  },
  getters: {
    PUSH_INFORMER: function(state){
      console.log('PUSH_INFORMER getter')
      return state.active
    }
  }
}
