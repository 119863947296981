import utils from '../functions/functions'
export default {
    state: {
        fullScreenActive: false
    },
    actions: {
        FULLSCREEN_CHANGE: function(context, data){
            // console.log('val', val)
            // console.log('vueContext', vueContext)
            if (data){
                utils.safeGoal('Fullscreen', data.vueContext)
            }
            context.commit('FULLSCREEN_UPDATE', data.fullscreenAtcive)
        }
    },
    mutations: {
        FULLSCREEN_UPDATE: function(state, val){
            state.fullScreenActive = val
        }
    },
    getters: {
        FULLSCREEN_MODE: function(state){
            return state.fullScreenActive
        }
    }
}