<template>
    <div>
        <GetContent 
            v-bind:dataFeed="dataFeed" 
            v-bind:unrefreshable="unrefreshable"
            v-on:contentGetted="contentGetted" 
            v-on:contentReset="contentReset"
        ></GetContent>        
        <div v-if="contentReady" :class="wrapClass+' app-block-append'">
            <OverlayBlockItem v-for="(item, index) in blockItems"
                v-bind:key = index
                v-bind:item = item
                v-bind:siteContent="siteContent"
                v-bind:border="border"
                v-bind:noName="noName"
                v-on:itemClick="itemClick"                        
            >
            </OverlayBlockItem>                        
            <!-- маркер прокрутки -->
            <div v-if="infinity" class="w-100 d-block" style="height: 1px;" v-b-visible="itemsIncrease"></div>                        
        </div>
        <div v-else :class="wrapClass+' app-preloader-append'">
            <OverlayBlockPreloader v-for="(item, index) in blockPreloader"
                v-bind:key="index"
                v-bind:siteContent="siteContent"
            ></OverlayBlockPreloader>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import utils from '../../functions/functions'
import OverlayBlockItem from './OverlayBlockItem'
import OverlayBlockPreloader from './OverlayBlockPreloader'
import GetContent from '../general/GetContent.vue'

export default {
    components: {
        GetContent,
        OverlayBlockItem,
        OverlayBlockPreloader
    },
    data(){
        return{
            blockPreloader: ['', '', '', '', '', ''], 
            blockItems: [],
            blockItemsLocked: [],
            wrapClass: 'd-flex flex-wrap justify-content-center justify-content-sm-between pt-3'
        }
    },
    props: ['goalName', 'infinity', 'limit', 'dataFeed', 'border', 'unrefreshable', 'noName'],    
    computed: {
        ...mapGetters(['REFRESHING']),
        contentReady: function(){
            // return (this.blockItems.length && !this.REFRESHING) ? true : false
            return (this.blockItems.length) ? true : false
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },        
    },
    methods: {
        itemsIncrease: function(isVisible){
            if (isVisible && this.blockItemsLocked.length){
                const newArr = utils.shuffleArray(this.blockItemsLocked)
                const limit = (this.limit) ? this.limit : newArr.length
                for (let i=0; i < limit; i++){
                    this.blockItems.push(newArr[i])
                }
            }
        },        
        itemClick: function(){
            const vueContext = this
            if (this.goalName){
                utils.safeGoal(this.goalName, vueContext)
                utils.safeGoal('GoLink', vueContext)
            }
        },
        arrayClone: function(arr){
            this.blockItems = []
            const fullArr = utils.shuffleArray(arr)
            const fullLength = fullArr.length            
            const limit = (this.limit) ? this.limit : fullLength
            this.blockItems = fullArr.slice(0, limit)
            this.blockItemsLocked = fullArr.slice(0, limit)
        },
        contentReset: function(){
            this.blockItems = []
        },        
        contentGetted: function(arr){
            this.arrayClone(arr)
        },        
    },
}
</script>
