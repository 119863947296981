<template>
    <div>
        <ScreenModal
                v-if="isDesktop && testPage"
                v-bind:dialogReady="dialogReady" 
                v-bind:delayTime="0"     
                v-bind:target="'push'"           
        ></ScreenModal>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import utils from '../../functions/functions'
import ScreenModal from './ScreenModal';

export default {
    components: {
        ScreenModal,
    },
    data(){
        return {
            cdnPath: 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js',
            appId: 'd8b5b0db-9c96-4de8-9075-76448d95f174',
            safari_web_id: 'web.onesignal.auto.00e855ed-5f66-45b8-ad03-54b1e142944e'
        }
    },
    computed: {
        ...mapGetters(['PLATFORM', 'PUSH_INFORMER']),
        oneSignalStartTrigger: function(){
            //return (this.PLATFORM === 'mobile')
            return true
        },
        testPage: function(){
            return (this.$route.query.p && this.$route.query.p === '385163')
        },
        dialogReady: function(){
            return this.PUSH_INFORMER
            //это дебажное условие, заменить на нормальное
            // return (this.testPage)
        },
        isDesktop: function(){
            return (this.PLATFORM === 'desktop')
        }
    },
    methods: {
        ...mapActions(['INFORMER_FIRE', 'INFORMER_HIDE'])
    },
    created: function(){
        console.log('test template')
        try{
            this.$OneSignal.init({ appId: this.appId })
            //вешаем обработчики
            this.$OneSignal.Notifications.addEventListener('permissionPromptDisplay', function(e){
                console.log('permissionPromptDisplay', e)
            })
            this.$OneSignal.Notifications.addEventListener('permissionChange', function(e){
                console.log('permissionChange', e)
            })
            //дисмисс
            this.$OneSignal.Notifications.addEventListener('dismiss', function(e){
                console.log('dismiss', e)
            })
            //чейндж
            this.$OneSignal.Notifications.addEventListener('change', function(e){
                console.log('change', e)
            })
            //показываем нотификатор
            this.$OneSignal.User.PushSubscription.optIn()

        }catch(e){
            console.log('Error cathced', e)
        }
        /*
        if(this.oneSignalStartTrigger){
            //заинитили
            this.$OneSignal.init({ appId: this.appId })
            //сабмитим цели
            const vueContext = this
            //показ
            this.$OneSignal.Notifications.addEventListener('permissionPromptDisplay', function(e){
                utils.safeGoal('permissionPromptDisplay', vueContext)
                console.log('permissionPromptDisplay', e)
                console.log('vueContext.testPage', vueContext.testPage)
                if (vueContext.testPage){
                    vueContext.INFORMER_FIRE()
                }
            })
            //подписка|неподписка
            this.$OneSignal.Notifications.addEventListener('permissionChange', function(e){
                utils.safeGoal('permissionChange', vueContext)
                const goalName = (e) ? 'pushAccept' : 'pushDecline'
                utils.safeGoal(goalName, vueContext)
                if (vueContext.testPage){
                    console.log('t p')
                    vueContext.INFORMER_HIDE()
                }                
            })
            //дисмисс
            this.$OneSignal.Notifications.addEventListener('dismiss', function(e){
                console.log('dismiss', e)
            })
            //чейндж
            this.$OneSignal.Notifications.addEventListener('change', function(e){
                console.log('change', e)
            })            

            //показываем нотификатор
            //пробуем через try-catch
            try {
                const opt = {
                    appId: "d8b5b0db-9c96-4de8-9075-76448d95f174", 
                    promptOptions: {
                        slidedown: {
                            prompts: {
                                autoPrompt: true,
                                dalay: {
                                    timeDelay: 5
                                }
                        }
                        }
                    }
                }
                if (this.testPage){
                    this.$OneSignal.User.PushSubscription.optIn(opt)
                }else{
                    this.$OneSignal.User.PushSubscription.optIn()
                }
                
            }catch(e){
                console.log('ERROR', e)
            }

            // console.log('OneSignal', OneSignal)
        }
            */
    },
    watch: {
        PUSH_INFORMER: function(){
            console.log('watch PUSH_INFORMER', this.PUSH_INFORMER)
        },
        dialogReady: function(){
            console.log('watch dialogReady', this.dialogReady)
        }        
    }
}
</script>
