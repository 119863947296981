<template>
    <div class="app-push-container top-fixed overflow-scroll px-2">
        123
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import utils from '../../functions/functions'
export default {
    comments: {
        BContainer, 
        BRow, 
        BCol
    },
    data(){
        return {
            blockItems: [],
            item: undefined,
            pushActive: false,
        }
    },
    props: ['dataFeed', 'siteContent', 'goalName', 'limit', 'timeInterval'],
    computed: {
        ...mapGetters(['SCREEN_SIZE', 'SMI24', 'INFOX', 'MIXED', 'POSTS'])        
    },
    methods: {
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)            
            this.blockItems = shuffled.slice(0, this.limit)
        },
        swipeUp: function(){
            const allLength = this.blockItems.length
            this.blockItems = this.blockItems.slice(0, (allLength-1))
            this.pushActive = false
        },

        itemClick: function(){
            const vueContext = this
            utils.safeGoal(this.goalName, vueContext)
        },
        pushDown: function(){
            let vueContext = this
            window.setInterval(function(){
                vueContext.pushActive = true
                const index = this.blockItems.length - 1
                this.item = this.blockItems[index]
            }, vueContext.timeInterval)        
        },
    },
    watch: {
        INFOX: function(){
        if (this.dataFeed === 'infox'  && !this.siteContent){
            this.arrayClone(this.INFOX)
        }
        },
        SMI24: function(){
        if (this.dataFeed === 'smi24'  && !this.siteContent){
            this.arrayClone(this.SMI24)
        }
        },
        MIXED: function(){
        if (this.dataFeed === 'mixed' && !this.siteContent){
            this.arrayClone(this.MIXED)
        }
        }, 
        POSTS: function(){
            if (this.siteContent){
            this.arrayClone(this.POSTS)
            }
        },        
    }
}
</script>

<style lang="sass">
.app-push-container
    background: rgba(pink, 0.5)
    height: 200px
</style>