<template>
    <b-link
        :class="classLink" 
        :style="styleParent"
        :href="linkHref"
        :to="linkTo"
        :target="target"
        @click="itemClick"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
    >
        <b-aspect :aspect="aspect" class="position-relative">
            <div class="position-absolute rounded d-flex justify-content-center align-items-start overflow-hidden" style="top:0; bottom: 0; left: 0; right: 0;">
                <img :src="imageSrc" :style="styleImg">
            </div>
        </b-aspect>
        <div class="position-absolute d-flex flex-column justify-content-end align-itemc-start pb-2 px-2" style="top: 0; left: 0; right: 0; bottom: 0;">
            <div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <!-- <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div> -->
            </div>
            <div :class="classTitle" :style="styleTitle">
                {{item.title }}
            </div>
            <div v-if="!adsMarker" class="align-self-start">
                <div v-if="!noName && item.name" class="app-block-name position-relative rounded-pill text-white px-3 py-0 bg-dark">
                    {{ item.name }}
                </div>
            </div>
            <div v-else>
                <b-badge  pill variant="success" class="position-relative">Читать с рекламой</b-badge>
            </div>
        </div>
    </b-link>
</template>


<script>
import { mapGetters } from 'vuex'
import {FastAverageColor} from 'fast-average-color'
import { BAspect, BLink, BBadge } from 'bootstrap-vue'
import utils from '../../functions/functions'
export default {
    components: {
        BAspect,
        BLink,
        BBadge
    },
    data(){
        return{
            mainColorRgb: '255, 255, 255',
            itemHover: false,
        }
    },
    props: ['item', 'siteContent', 'vertical', 'border', 'noName'],
    computed: {
        ...mapGetters(['ACTIVE_NOW', 'PLATFORM']),
        classTitle: function(){
            const classBase = 'app-block-title position-relative mb-2 font-weight-bold '
            const textModificator = (this.mainColorRgb === '255, 255, 255') ? '' : 'text-white'
            return classBase+textModificator
        },   
        styleTitle: function(){
            const fontProps = (this.mainColorRgb === '255, 255, 255') ? 'color: black; text-shadow: rgb(255, 255, 255) 0 0px 4px, rgb(255, 255, 255) 0 0px 4px, rgb(255, 255, 255) 0 0px 4px;' : 'text-shadow: 0 0 4px #000000;'
            const fontSize = (this.vertical) ? 'font-size: 1rem' : 'font-size: 1.24rem'
            return `${fontProps} ${fontSize}`
        },              
        imageSrc: function(){
            return this.item.img.replaceAll('100_100', '400_400')
        },
        styleColorOverlay: function(){
            const radialGradient = `background:radial-gradient(100% 100% at 50% 0px, transparent 50%, rgb(${this.mainColorRgb}) 100%) rgba(0, 0, 0, 0.2)`
            return (this.mainColorRgb) ? radialGradient : null
        },
        styleParent: function(){
            return `background:rgb(${this.mainColorRgb})`
        },
        styleImg: function(){
            const ratio = (this.item.img.match(/smi2\.|cvnqiv\.com|eqa7575y2m/i)) ? 'height:100%;width:auto;' : ''
            const filter = (this.itemHover) ? 'filter:brightness(1.2)' : ''
            return `${ratio} ${filter}`
        },
        classLink: function(){
            const classBase = 'app-block position-relative rounded overflow-hidden flex-shrink-0 mb-3'
            const paddingBottom = (this.siteContent) ? 'pb-5' : 'pb-3'
            const width = (this.vertical) ? 'w-100' : ''
            const border = (this.border) ? 'border' : ''
            const borderColor = (this.itemHover) ? 'border-white' : 'border-'+this.border
            return `${classBase} ${paddingBottom} ${width} ${border} ${borderColor}`
        },
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : this.item.url
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        aspect: function(){
            return (this.siteContent) ? '16:9' : '1:1'
        },
        adsMarker: function(){
            const vueContext = this
            return (utils.isRotator(vueContext) && this.item.url.match(/smi2\./i))
        }                
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        },
        mouseEnter: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? true : this.itemHover
        },
        mouseLeave: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? false : this.itemHover
        },                
    },
    beforeMount: function(){
        if (!this.item.rgb){
            const imgPath = (this.siteContent) ? this.item.imgMicro : this.item.img
            const fac = new FastAverageColor()
            fac.getColorAsync(imgPath)
                .then(color => {
                    const colorString = color.rgb
                    const match = colorString.match(/([\d]+),([\d]+),([\d]+)/i)
                    this.mainColorRgb = `${match[1]},${match[2]},${match[3]}`
                })
                .catch(e => {
                    console.log(e);
                })
        }else{
            this.mainColorRgb = this.item.rgb
        }
    },
}
</script>

<style lang="sass">
.app-block
    width: 100%
@media (min-width: 576px)
    .app-block
        width: calc(50% - 10px)
@media (min-width: 992px)     
    .app-block
        width: calc(33.3% - 16px)       
.app-block-name
    font-size: 0.7rem
    opacity: 0.5
.app-block-title    
    transition: all 0.15s
    // text-shadow: 0 0 4px #000000
</style>

<style lang="sass" scoped>
a
    transition: all 0.2s
img
    width: 100%
    height: auto
    transition: all 0.2s
</style>