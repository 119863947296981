<template>
    <div class="app-image-overlay position-fixed d-flex flex-column justify-content-end pb-5" :style="overlayShow">
        <b-button variant="outline-light" class="app-image-overlay__close position-absolute" @click="overlayClose">
            <span class="mr-3">Закрыть</span>
            <b-icon icon="x"></b-icon>
        </b-button>        
        <div class="app-image-overlay__wrap position-absolute" @click="overlayClose">
            <img :src="img" class="app-image-overlay__img position-absolute" :style="imgStyle">
        </div>
        <section class="app-image-overlay__slider-section" :style="sectionStyle">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock1'"
                            v-bind:dataFeed="'mixed'"
                            v-bind:fullWidth="true"
                        >
                        
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>    
</template>


<script>
import {mapGetters} from 'vuex'
import {BButton, BContainer, BRow, BCol} from 'bootstrap-vue'
import CarouselBlock from '../integrations/CarouselBlock.vue'
import utils from '../../functions/functions'
export default {
    components: {
        CarouselBlock,
        BButton, 
        BContainer, 
        BRow, 
        BCol
    },
    data(){
        return {
            moveStart: false
        }
    },
    props: ['imageOverlay', 'img', 'posX', 'posY', 'startWidth'],
    computed: {
        ...mapGetters(['SCREEN_SIZE']),
        overlayShow: function(){
            return (this.imageOverlay) ? '' : 'display:none !important;'
        },
        imgStyle: function(){
            const img = new Image()
            img.src = this.img
            const imageWidth = 720//img.naturalWidth
            const imageHeight = img.naturalHeight
            const posX = Math.floor((window.innerWidth - imageWidth)/2)
            const posY = Math.floor((window.innerHeight - imageHeight)/2) - 120
            const imageStart = `left:${this.posX}px; top:${this.posY}px; width:${this.startWidth}px; height:auto;`
            // console.log('imageStart', imageStart)
            const imageFinish = `left:${posX}px; top:${posY}px; width:100%; max-width:720px; height:auto;`
            // console.log('imageFinish', imageFinish)
            return (!this.moveStart) ? imageStart : imageFinish
        }, 
        sectionStyle: function(){
            return (!this.moveStart) ? 'margin-bottom:-340px' : ''
        }       
    },
    methods: {
        overlayClose: function(){
            this.$emit('overlayClose')
        }
    },
    mounted: function(){
    },
    watch: {
        imageOverlay: function(newVal, oldVal){
            // console.log('this.imageOverlay', this.imageOverlay)
            const vueContext = this
            if (newVal && !oldVal){
                utils.safeGoal('ImageOverlayOpen', vueContext)
                window.setTimeout(function(){
                    vueContext.moveStart = true
                }, 1000)
            }else{
                utils.safeGoal('ImageOverlayClose', vueContext)
                this.moveStart = false
            }
        }
    }
}
</script>

<style lang="sass">
.app-image-overlay
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba(0,0,0, 0.95)
    z-index: 2
.app-image-overlay__wrap    
    top: 0
    bottom: 0
    left: 0
    right: 0
.app-image-overlay__img    
    transition: all 0.6s
.app-image-overlay__close
    top: 16px
    right: 16px   
    z-index: 3 
.app-image-overlay__slider-section    
    transition: all 0.6s ease-in-out 0.6s
</style>