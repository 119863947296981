import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PostView from '../views/PostView.vue'
import RotatorView from '../views/RotatorView.vue'
import HardTestView from '../views/HardTestView.vue'
import NotFound from '../views/404View.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/post',
    name: 'post',
    component: PostView
  },
  {
    path: '/app',
    name: 'app',
    component: RotatorView
  },
  {
    path: '/pwa',
    name: 'pwa',
    component: RotatorView
  },       
  {
    path: '/pwa1',
    name: 'pwa1',
    component: RotatorView
  },
  {
    path: '/pwa2',
    name: 'pwa2',
    component: RotatorView
  },    
  {
    path: '/pwa3',
    name: 'pwa3',
    component: RotatorView
  },
  {
    path: '/pwa4',
    name: 'pwa4',
    component: RotatorView
  },
  {
    path: '/pwa5',
    name: 'pwa5',
    component: RotatorView
  },
  {
    path: '/pwa6',
    name: 'pwa6',
    component: RotatorView
  },      
  {
    path: '/pwa7',
    name: 'pwa7',
    component: RotatorView
  },  
  {
    path: '/hardtest',
    name: 'hardtest',
    component: HardTestView
  },
  {
    path: '/404',
    name: 'notFound',
    component: NotFound
  },  
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    return { x: 0, y: 0 }
  }
})

export default router
