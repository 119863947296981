<template>
    <section class="bg-dark py-5 mt-5">
        <b-container>
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-center align-items-center">
                        &copy; {{ siteName }} {{ currentYear }}
                    </div>
                </b-col>
            </b-row>
        </b-container>        
    </section>
</template>


<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'

export default {
    components: {
        BContainer, 
        BRow, 
        BCol
    },
    computed: {
        siteName: function(){
            const hayStack = process.env.VUE_APP_HOST_NAME
            const matches = hayStack.match(/\/\/(.+\..+)\//i)
            return matches[1]
        },
        currentYear: function(){
            const d = new Date();
            return d.getFullYear()
        }
    }
    //VUE_APP_HOST_NAME
}
</script>
