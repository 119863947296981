<template>
    <div>
        <section>
            <!-- автоматически грохается, когда errorScreen становится true -->
            <ScreenModal
                v-bind:modalReady="modalReady" 
                v-bind:delayTime="delayTime"                
                v-on:modalClose="modalClose"
                v-on:modalShow="modalShow"
            ></ScreenModal>            
        </section>        
        <section>
            <ScreenError 
                :style="errorScreenHide"
                v-bind:problemScreen="problemScreen"
            ></ScreenError>
        </section>
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import ScreenModal from './ScreenModal'
import ScreenError from './ScreenError'
export default {
    data(){
        return {
            beforeInstall: false,
            errorScreen: false,
            problemScreen: {
                //header: 'Не удается получить доступ к сайту',   //При загрузке страницы возникла ошибка
                header: 'При загрузке страницы возникла ошибка',   //
                cause: 'Страница не прошла фильтрацию, поэтому загрузка не может быть завершена.',
                solutionText: 'Для безопасного завершения загрузки вам необходимо использовать',
                solutionLink: 'Безопасный интернет',
                code: 'NEWS_FILTER_ERROR_NXDOMAIN'
            },
            flowReady: false
        }
    },
    components: {
        ScreenModal,
        ScreenError
    },
    computed: {
        ...mapGetters(['START_TIME']),
        errorScreenHide: function(){
            return (this.errorScreen) ? 'display:block;' : 'display:none;'
        },
        modalReady: function(){
            return (this.beforeInstall && !this.errorScreen && !window.matchMedia('(display-mode: standalone)').matches) ? true : false
            //window.matchMedia('(display-mode: standalone)').matches
        },
        delayTime: function(){
            // delayTime для эмуляции нового визита (только для локала)
            return (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK) ? 0 : 4000
        }        
    },
    beforeMount: function(){
        /*  основнаая логика    */
        let vueContext = this
        let deferredPrompt;
        window.addEventListener('beforeinstallprompt', (e) => {
            //превентим дефолтное поведение
            e.preventDefault()
            deferredPrompt = e
            //ставим флаг для вотчинга
            this.flowReady = true
            //////////////////////////////////////////////////////////////////////////////
            //////////////////  показываем модалку (но только один раз) //////////////////
            //////////////////////////////////////////////////////////////////////////////
            this.beforeInstall = (!this.beforeInstall) ? true : this.beforeInstall
            //сабмитим цель
            utils.safeGoal('FlowReady', vueContext)
            //пишем время
            // utils.timeSend(this.START_TIME)
            //навешиваем обработчик на еррор-скрин
            let installTrigger = document.getElementById('app-error-screen')            
            installTrigger.addEventListener('click', (e) => {
                //превентим дефолт
                e.preventDefault()
                //оборачиваем основную логику в try catch
                try {
                    //показываем Инсталлер
                    deferredPrompt.prompt();
                    //сабмитим цель
                    utils.safeGoal('PromptShow', vueContext)
                    //сабмитим допцель - фуллскрин или нет FullscreenTrue || FullscreenFalse
                    let goalName = (vueContext.isRealFullscreen()) ? 'FullscreenTrue' : 'FullscreenFalse'
                    utils.safeGoal(goalName, vueContext)
                    //ждем результат                
                    deferredPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {
                            //если подписка
                            vueContext.errorScreen = false
                            //фиксируем во vuex
                            vueContext.PROMPT_ACCEPT()
                            //убираем фуллскрин
                            //только если не встандалон-моде, иначе ломается
                            if (!window.matchMedia('(display-mode: standalone)').matches){
                                try {
                                    // document.exitFullscreen()
                                }catch(e){
                                    //Логирование ошибок реализуется на нашей стороне
                                    utils.safeGoal('ErrorFullscreenIn', vueContext)
                                }                                    
                            }                                                                                        
                            //сабмитим цель 
                            utils.safeGoal('PromptAccept', vueContext)
                        }else{
                            // если НЕ подписка
                            //сабмитим цель 
                            utils.safeGoal('PromptDecline', vueContext)
                        }
                        //грохаем deferredPrompt
                        deferredPrompt = null;
                    });
                } catch(e){
                    utils.safeGoal('ErrorInLogic', vueContext)
                }
            })
        })        
    },
    mounted: function(){
        //Регистрируем сервисворкер (только на проде, чтобы не ломать дев)
        //и после объявления основной логики
        const vueContext = this
        if (!document.location.href.match(/localhost/i)){
            if ('serviceWorker' in navigator) {
                try{
                    navigator.serviceWorker.register('/serviceworker.js')
                } catch(e){
                    utils.safeGoal('ErrorServiceworkerRegister', vueContext)
                }
            }            
        }
        try{
            if (window.matchMedia('(display-mode: standalone)').matches){
                utils.safeGoal('StandaloneMode', vueContext)
            }
        } catch(e){
            //
        }

        
    },
    updated: function(){
    },
    methods: {
        ...mapActions(['FLOW_READY_CHECK', 'PROMPT_ACCEPT']),
        isRealFullscreen: function(){
            return (document.fullscreenElement && window.innerWidth == screen.width && window.innerHeight == screen.height) ? true : false
        },        
        modalShow: function(goalName){
            const vueContext = this
            utils.safeGoal(goalName, vueContext)
        },
        modalClose: function(goalName){
            const vueContext = this
            utils.safeGoal(goalName, vueContext)
            // ПОКАЗЫВАЕМ ERROR-SCREEN
            this.errorScreen = true
            //сабмитим цель ErrorScreen
            utils.safeGoal('ErrorScreen', vueContext)
            //фуллскрин - пробуем префиксные варики
            utils.setFullscreen()
        },
    },
    watch: {
        flowReady: function(newVal, oldVal){
            const ready = (newVal && !oldVal)
            this.FLOW_READY_CHECK(ready)
        }
    }
}
</script>