export default {
    state: {
        track: [undefined],
    },
    actions: {
        TRACK_ROUTE(context, routeName){
            context.commit('TRACK_UPDATE', routeName)
        },
    },
    mutations: {
        TRACK_UPDATE(state, val){
            state.track.push(val)
        },
    },
    getters: {
        LAST_ROUTE(state){
            const index = state.track.length - 1
            return state.track[index]
        },
    }
}