<template>
    <div :class="wrapClass">
        <b-aspect :aspect="aspect"></b-aspect>
        <div class="position-absolute" style="top: 0; bottom: 0; left: 0; right: 0;">
            <b-skeleton height="100%"></b-skeleton>
        </div>        
    </div>
</template>


<script>
import { BSkeleton, BAspect } from 'bootstrap-vue'

export default {
    components: {
        BAspect,
        BSkeleton
    },    
    props: ['aspect', 'siteContent', 'vertical'],
    computed: {
        wrapClass: function(){
            const classBase = 'app-block position-relative rounded overflow-hidden flex-shrink-0 mb-3 '
            const paddingBottom = (this.siteContent) ? 'pb-5 ' : 'pb-3 '
            const width = (this.vertical) ? 'w-100' : ''
            return classBase + paddingBottom + width

        }
    },
    mounted: function(){

    }
}
</script>