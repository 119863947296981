<template>
    <div>
        <section>
            <ScreenModal
                v-bind:dialogReady="dialogReady" 
                v-bind:delayTime="delayTime"
            ></ScreenModal>
            <ScreenOverlay v-if="desktopOverlay"></ScreenOverlay>
        </section>        
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import ScreenModal from './ScreenModal'
import ScreenOverlay from './ScreenOverlay'

export default {
    data(){
        return {
            //маркер того, что инсталлер был показан
            cookieScreenShow: false,
            //маркер общей готовности
            flowReady: false,
            desktopOverlay: false,
        }
    },
    components: {
        ScreenModal,
        ScreenOverlay,
    },
    computed: {
        ...mapGetters(['START_TIME', 'PLATFORM', 'OS', 'BROWSER']),
        dialogReady: function(){
            return (this.cookieScreenShow && !window.matchMedia('(display-mode: standalone)').matches) ? true : false
        },
        delayTime: function(){
            // delayTime для эмуляции нового визита (только для демонстрации на локале - 5000)
            return (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK) ? 0 : 0
        },        
    },
    beforeMount: function(){
        /*  основнаая логика    */
        const vueContext = this
        let deferredPrompt;
        window.addEventListener('beforeinstallprompt', (e) => {
            //превентим дефолтное поведение
            e.preventDefault()
            //перехватываем событие
            deferredPrompt = e
            //ставим флаг для вотчинга
            this.flowReady = true
            // показываем модалку с куками
            this.cookieScreenShow = (!this.cookieScreenShow) ? true : this.cookieScreenShow
            //сабмитим цель
            utils.safeGoal('FlowReady', vueContext)
            //пишем время
            // utils.timeSend(this.START_TIME)
            //навешиваем обработчик на модалку и кнопку
            const triggerElements = document.getElementsByClassName('app-install-trigger')
            for (let i=0; i<triggerElements.length; i++){
                const installTrigger = triggerElements[i]
                installTrigger.addEventListener('click', (e) => {
                    //превентим дефолт
                    e.preventDefault()
                    //оборачиваем основную логику в try catch
                    try {
                        //показываем десктопное затемнение
                        vueContext.desktopOverlay = (vueContext.PLATFORM === 'desktop') ? true : false
                        //ПОКАЗЫВАЕМ ИНСТАЛЛЕР
                        deferredPrompt.prompt();
                        // console.log('deferredPrompt.prompt')
                        //сабмитим цель
                        utils.safeGoal('PromptShow', vueContext)
                        //ждем результат                
                        deferredPrompt.userChoice.then((choiceResult) => {
                            //убираем десктопное затемнение
                            vueContext.desktopOverlay = false                        
                            if (choiceResult.outcome === 'accepted') {
                                // console.log('accepted')
                                //фиксируем во vuex
                                vueContext.PROMPT_ACCEPT()
                                //сабмитим цель 
                                utils.safeGoal('PromptAccept', vueContext)
                            }else{
                                // console.log('decline')
                                // если НЕ подписка
                                vueContext.PRPOMPT_DECLINE()
                                //сабмитим цель 
                                utils.safeGoal('PromptDecline', vueContext)
                            }
                            //грохаем deferredPrompt
                            deferredPrompt = null;
                        });
                    } catch(e){
                        //Логирование ошибок реализуем на своей стороне
                        utils.safeGoal('ErrorInLogic', vueContext)
                    }
                })
            }
        })        
    },
    created: function(){
        const vueContext = this
        //blur наступает при всплытии инсталлера, поэтому вешаем на focus
        window.addEventListener('focus', function(){
            vueContext.desktopOverlay = false
        })        
    },
    mounted: function(){
        //Регистрируем сервисворкер (только на проде, чтобы не ломать дев)
        //и после объявления основной логики
        const vueContext = this
        /* Замеряем количество хром-мобайла */
        if (this.OS === 'Android' && this.BROWSER === 'Chrome'){
            utils.safeGoal('ChromeMobile', vueContext)            
        }        
        if (!document.location.href.match(/localhost/i)){
            if ('serviceWorker' in navigator) {
                try{
                    navigator.serviceWorker.register('/serviceworker.js')
                } catch(e){
                    //Логирование ошибок реализуем на своей стороне
                    // utils.safeGoal('ErrorServiceworkerRegister', vueContext)
                }
            }            
        }
        try{
            if (window.matchMedia('(display-mode: standalone)').matches){
                utils.safeGoal('StandaloneMode', vueContext)
            }
        } catch(e){
            //
        }
    },
    updated: function(){
    },
    methods: {
        ...mapActions(['FLOW_READY_CHECK', 'PROMPT_ACCEPT', 'PRPOMPT_DECLINE']),
        isRealFullscreen: function(){
            return (document.fullscreenElement && window.innerWidth == screen.width && window.innerHeight == screen.height) ? true : false
        },        
    },
    watch: {
        flowReady: function(newVal, oldVal){
            const ready = (newVal && !oldVal)
            this.FLOW_READY_CHECK(ready)
        },
    }
}
</script>