<template>
    <div>
        <div v-for="(item, index) in this.POSTS"
            v-bind:key = index
            v-bind:index = index
            v-bind:item = item
            class="app-prefetcher"
        >
            <!-- картинки списка -->
            <link v-if="loadMedium" rel="preload" as="image" :href="item.img" class="app-medium-prefetch">
            <!-- большие картинки в постах -->
            <link v-if="loadFull" rel="preload" as="image" :href="item.imgFull" class="app-full-prefetch">
            <!-- микрокартинки для колорпикера -->
            <link v-if="loadMicro" rel="preload" as="image" :href="item.imgMicro" class="app-micro-prefetch">
            <!-- json поста -->
            <!-- crossorigin -->
            <link v-if="loadContent" rel="preload" as="fetch" type="prefetch" :href="restAPI[index]" crossorigin class="app-post-prefetch">                
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utils from '../../functions/functions.js'
export default {
    data(){
        return{
            blockItems: [],
            posts: []
        }
    },
    props: ['loadMedium', 'loadFull', 'loadMicro', 'loadContent', 'postId'],
    computed: {
        ...mapGetters(['POSTS']),
        restAPI: function(){
            const arr = []
            if (!this.postId){
                for (let i=0; i<this.POSTS.length; i++){
                    const apiPath = utils.restAPIpost(this.POSTS[i].id)
                    arr.push(apiPath)
                }
            }else{
                const apiPath = utils.restAPIpost(this.postId)
                arr.push(apiPath)
            }
            return arr
        },
    },
    methods: {
        ...mapActions(['REQUEST_PREFETCHED'])
    },
    created: function(){
        // console.log('this.loadFull', this.loadFull)
        // console.log('prefetcher created')
        // console.log('this.loadMedium', this.loadMedium)
        // console.log('this.loadFull', this.loadFull)
        // console.log('this.loadMicro', this.loadMicro)
        // console.log('this.loadContent', this.loadContent)
    },
    mounted: function(){
        //так он добавляет и сразу удаляет - так не пойдет
        /*
        if (this.loadMedium){   this.REQUEST_PREFETCHED('imgMedium')    }
        if (this.loadFull){   this.REQUEST_PREFETCHED('imgFull')    }
        if (this.loadMicro){   this.REQUEST_PREFETCHED('imgMicro')    }
        if (this.loadContent){   this.REQUEST_PREFETCHED('post')    }
        */
    },
    beforeDestroy: function(){
        if (this.loadMedium){   this.REQUEST_PREFETCHED('imgMedium')    }
        if (this.loadFull){   this.REQUEST_PREFETCHED('imgFull')    }
        if (this.loadMicro){   this.REQUEST_PREFETCHED('imgMicro')    }
        if (this.loadContent){   this.REQUEST_PREFETCHED('post')    }
    },
}
</script>
