<template>
    <div class="app-post">
        <!-- здесь будет условие -->
        <!-- POST.title -->
        <h1 v-if="contentReady" class="my-3 my-lg-4 font-weight-bold text-black">
            {{ POST.title }}
        </h1>
        <div v-else class="d-flex flex-column my-3 my-lg-4">
            <b-skeleton height="24px"></b-skeleton>
            <b-skeleton height="24px" width="80%" class="d-lg-none"></b-skeleton>
            <b-skeleton height="24px" width="60%" class="d-sm-none"></b-skeleton>
        </div>
        <div class="app-post__head d-flex flex-column flex-lg-row align-items-lg-start">
            <!-- mt-lg-2 -->
            <b-aspect 
                :aspect="aspect"
                class="app-post__image flex-shrink-0 d-flex justify-content-center align-items-start position-relative rounded overflow-hidden"
                >
                <div>
                    <div class="position-absolute overflow-hidden" style="top: 0; bottom: 2px; left: 0; right: 0">
                        <b-skeleton height="100%" class="my-0"></b-skeleton>
                    </div>
                    <div @click="imageClick">
                        <b-img-lazy :src="POST.img" class="position-relative rounded" id="app-post-image"></b-img-lazy>
                    </div>
                </div>
            </b-aspect>
            <div class="app-post__head-items d-flex flex-column justify-content-between mt-3 mt-lg-0 ml-lg-4 w-100">
                <div v-if="contentReady" class="app-post__text-lead text-dark">
                    <p>
                        {{ POST.text[0] }}
                    </p>
                    <p v-if="SCREEN_SIZE >= mediaBreakpoint && POST.text[1]">
                        {{ POST.text[1] }}
                    </p>
                </div>
                <div v-else class="d-flex flex-column">
                    <b-skeleton class="mb-4"></b-skeleton>
                    <b-skeleton class="d-none d-lg-block mb-2"></b-skeleton>
                    <b-skeleton class="d-none d-lg-block mb-2"></b-skeleton>
                    <b-skeleton class="d-none d-lg-block mb-2"></b-skeleton>
                    <b-skeleton class="d-none d-lg-block mb-2"></b-skeleton>
                </div>

                <div v-if="contentReady && !FULLTEXT && fullscreenEnable">
                    <!-- !hasReferrer -->
                    <b-button v-if="!POST.url"
                        variant="primary" 
                        @click="buttonClick"
                        size="lg"
                        block
                    >
                        <span>Читать полностью</span>
                        <b-icon icon="three-dots" class="ml-2"></b-icon>
                    </b-button>
                    <a v-else
                        :href="POST.url" target="_blank" class="btn btn-primary btn-lg btn-block" @click="linkClick">
                        <span>Читать полностью</span>
                        <b-icon icon="three-dots" class="ml-2"></b-icon>                    
                    </a>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-show="FULLTEXT || !fullscreenEnable" class="mt-lg-3 text-dark">
                <!-- Тройной текстовый -->
                <b-alert
                    v-if="hasReferrer"
                    :show="true"
                    variant="danger"
                    
                >
                    <TextBlock
                        v-bind:limit="textBlockLimit"
                        v-bind:dataFeed="'mixed'"                        
                        v-bind:goalName="'TextBlock'"
                        v-bind:icon="'exclamation-octagon-fill'"
                        v-bind:fontWeight="'semi-bold'"
                        v-bind:hoverable="true"
                        v-bind:borderColor="'secondary'"
                        v-bind:uppercase="false"
                        v-bind:tooltip="true"
                    ></TextBlock>            
                </b-alert>
                <div>
                    <!-- in textUnderCut2 -->
                    <PostContentTextItem v-for="(item, index) in textUnderCut"
                        v-bind:key="index"
                        v-bind:index="index"
                        v-bind:text="item"
                        v-bind:max="textUnderCut.length"
                    >
                    </PostContentTextItem>  
                    <BlockRounded v-if="hasReferrer"
                        v-bind:dataFeed="'mixed'"
                        v-bind:goalName="'BlockRounded'"
                    ></BlockRounded>              
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {  mapActions, mapGetters } from 'vuex'
import utils from '../../functions/functions'
import TextBlock from '../integrations/TextBlock.vue'
import PostContentTextItem from './PostContentTextItem.vue'
import ImageOverlay from './ImageOverlay.vue'
import BlockRounded from '../integrations/BlockRounded'
import { BButton, BAspect, BImgLazy, BSkeleton, BAlert } from 'bootstrap-vue'
export default {
    components: {
        BButton,
        BAspect,
        BImgLazy, 
        BSkeleton,
        TextBlock, 
        PostContentTextItem,
        ImageOverlay,
        BAlert,
        BlockRounded
    },
    data(){
        return {
            textArray: [],
            mediaBreakpoint: 4,
            textBreak: 3,
            imageOverlay: false,
            posYrendered: 0,
            posX: 0,
            posY: 0,
            startWidth: 0,
            delayFinished: false,
        }
    },
    props: ['delayTime'],
    computed: {
        ...mapGetters(['POST', 'SCREEN_SIZE', 'FULLTEXT', 'IS_EXTERNAL', 'POSTS', 'POST_LIST_BY_ID']),
        textUnderCut: function(){
            const trimStart = (this.SCREEN_SIZE >= this.mediaBreakpoint) ? 2 : 1
            const max = this.POST.text.length
            return this.POST.text.slice(trimStart, max)
        },
        textUnderCut1: function(){
            const trimStart = (this.SCREEN_SIZE >= this.mediaBreakpoint) ? 2 : 1
            return this.POST.text.slice(trimStart, this.textBreak)
        },
        textUnderCut2: function(){
            const max = this.POST.text.length
            return this.POST.text.slice(this.textBreak, max)
        },
        isDirectHit: function(){
            return !utils.hasReferrer()
        },        
        pageUrl: function(){
            return document.location.href
        },
        prelandConditions: function(){
            return utils.hasReffererSmi24()
        },
        hasReferrer: function(){
            return utils.hasReferrer()
        },
        prelandMode: function(){
            return (document.cookie.match(/prelandMode=1/i)) ? true : false
        },
        isProd: function(){
            return utils.isProd()
        },
        contentReady: function(){
            return (this.POST.title && this.POST.text.length /*&& this.delayFinished*/) ? true : false
        },
        fullscreenEnable: function(){
            return (utils.fullScreenAbility())
            // return false
        },
        aspect: function(){
            // return (this.IS_EXTERNAL) ? '1:1' : '16:9'
            return '16:9'
        },
        textBlockLimit: function(){
            return (utils.hasReferrer()) ? 3 : 2
        }
    },    
    methods: {
        ...mapActions(['FETCH_POST', 'RESET_POST', 'TEXT_STATE_SET', 'SET_POST']),
        imageClick: function(){
            // this.imageOverlay = true
            if (utils.fullScreenAbility()){
                const vueContext = this
                utils.safeGoal('ImageClick', vueContext)
                utils.setFullscreen()
            }
        },
        overlayClose: function(){
            this.imageOverlay = false
        },
        buttonClick: function(){
            this.TEXT_STATE_SET(true)
            const vueContext = this
            utils.safeGoal('ReadMore', vueContext)     
            utils.setFullscreen()
        },
        linkClick: function(){
            const vueContext = this
            utils.safeGoal('readmoreLink', vueContext)
            utils.safeGoal('GoLink', vueContext)
        }
        /*
        goPreLand: function(){
            document.cookie = 'prelandMode=1'
            const vueContext = this
            utils.safeGoal('GoPreland', vueContext)
            this.$emit('goPreLand')
        }
        */
    },   
    created: function(){
        //ДЛЯ ДЕБАГА
        //this.TEXT_STATE_SET(true)
        if (this.delayTime){
            this.delayFinished = false
            const vueContext = this
            window.setTimeout(function(){
                vueContext.delayFinished = true
            }, vueContext.delayTime )
        }else{
            this.delayFinished = true
        }
        const id = utils.getIdFromAddressBar()
        const loadedPost = this.POST_LIST_BY_ID[id]
        //если уже есть список и посты полнотекстовые
        if (loadedPost && loadedPost.text.length){
            //выставляем пост из уже зафетченных
            this.SET_POST(loadedPost)
        }else{
            //фетчим пост
            this.FETCH_POST(id)
        }

    },
    beforeMount: function(){
        if (!utils.hasReferrer()){
            this.TEXT_STATE_SET(true)
        }
    },
    beforeDestroy: function(){
        this.RESET_POST()
    },
    watch: {
        imageOverlay: function(newVal, oldVal){
            if (newVal && !oldVal){
                const imgWrap = document.getElementById('app-post-image-wrap')
                const viewportOffset = imgWrap.getBoundingClientRect()
                this.posYrendered = Math.floor(viewportOffset.top)
                this.posY = this.posYrendered
                this.posX = Math.floor(viewportOffset.left)
                this.startWidth = Math.floor(imgWrap.clientWidth)
            }
        },
    },
}
</script>


<style lang="sass" scoped>
img
    width: 100%
    height: auto
    cursor: zoom-in
h1
    font-weight: 500
    font-size: 1.3rem   
section
    // min-height: 410px     
</style>

<style lang="sass">
@media (min-width: 992px)
    .app-post__image
        flex-basis: 33.3%
@keyframes content-append 
    0%
        opacity: 0
    100%
        opacity: 1

</style>


