<template>
    <div>
        <GetContent 
            v-bind:dataFeed="dataFeed" 
            v-on:contentGetted="contentGetted"
            v-bind:unrefreshable="unrefreshable"
        ></GetContent>
        <b-alert :show="true" variant="secondary" class="d-flex flex-column flex-md-row justify-content-between mb-0 p-2 px-md-3 py-md-2 px-lg-4 py-lg-3">
            <BlockRoundedItem v-for="(item, index) in blockItems"
                v-bind:key="index"
                v-bind:item="item"
                v-bind:siteContent="siteContent"
                v-bind:maxIndex="blockItems.length" 
                v-bind:currentIndex="index" 
                v-on:itemClick="itemClick"
            ></BlockRoundedItem>
        </b-alert>        
    </div>
</template>

<script>
import utils from '../../functions/functions'
import BlockRoundedItem from './BlockRoundedItem.vue'
import GetContent from '../general/GetContent.vue'
import { BAlert } from 'bootstrap-vue'
export default {
    components: {
        GetContent,
        BAlert,
        BlockRoundedItem
    },
    data(){
        return{
            blockItems: [],            
        }
    },
    props: ['goalName', 'dataFeed', 'unrefreshable'],
    computed: {
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        }
    },
    methods: {
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)
            this.blockItems = shuffled.slice(0, 2)            
        },
        itemClick: function(){
            const vueContext = this
            if (this.goalName){
                utils.safeGoal(this.goalName, vueContext)
                utils.safeGoal('GoLink', vueContext)
            }
        },
        contentGetted: function(arr){
            this.arrayClone(arr)
        }                
    },
    watch: {

    }    

}
</script>