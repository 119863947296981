<template>
    <div>
        <b-link 
            :href="linkHref" 
            :to="linkTo" 
            :target="target"
            :class="linkClass" 
            :style="linkStyle"
            @click="itemClick"
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"
            >
            <div :id="id" class="d-flex align-items-center">
                <b-icon :icon="icon" class="position-relative mr-3"></b-icon>
                <span class="position-relative">
                    {{ item.title }}
                </span>  
                <b-badge v-if="adsMarker" pill variant="success" class="ml-3">Читать с рекламой</b-badge>
            </div>
        </b-link>
        <!-- <b-tooltip
            v-if="tooltipConditions" 
            :target="id" 
            triggers="hover" 
            :placement="tooltipPosition" 
            boundary-padding="20" 
            fallback-placement="counterclockwise" 
            custom-class="app-item-text-tooltip"
        >
             <b-link
                :href="linkHref" 
                :to="linkTo" 
                :target="target"
                class="d-flex flex-column text-white p-1"
                @click="tooltipClick"
             >
                <b-aspect 
                    :aspect="aspect" 
                    class="bg-white flex-shrink-0 d-flex justify-content-center align-items-start position-relative rounded overflow-hidden"
                    style="width: 160px;"
                >
                    <div class="position-absolute" style="top: 0; bottom: 0; left: 0; right: 0">
                        <b-skeleton width="100%" height="100%"></b-skeleton>
                    </div>
                    <div class="position-absolute d-flex justify-content-center align-items-center overflow-hidden" style="top:0;bottom:0;left:0;right:0;">
                        <b-img-lazy :src="item.img" style="height:100%; width:auto;"></b-img-lazy>
                    </div>
                </b-aspect>
                <span class="d-flex text-white" variant="primary" size="sm">Подробнее...</span>
            </b-link>
        </b-tooltip>         -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BLink, BTooltip, BAspect, BSkeleton, BImgLazy, BButton, BBadge } from 'bootstrap-vue'
import utils from '../../functions/functions'
export default {
    components: {
        BLink,
        BTooltip,
        BAspect,
        BSkeleton,
        BImgLazy,
        BButton,
        BBadge
    }, 
    data(){
        return {
            itemHover: false
        }
    },   
    props: ['item', 'currentIndex', 'maxIndex', 'siteContent', 'icon', 'hoverable', 
    'fontWeight', 'borderColor', 'uppercase', 'tooltip'],
    computed: {
        ...mapGetters(['SCREEN_SIZE', 'PLATFORM']),
        linkClass: function(){
            const classBase = 'app-text-item d-flex align-items-center py-2 text-decoration-none position-relative'
            const color = (this.itemHover && this.hoverable) ? 'text-primary' : 'text-black'
            const borderBottom = (this.currentIndex != (this.maxIndex - 1)) ? 'border-bottom ' : ''
            const borderColor = (this.borderColor) ? `border-${this.borderColor}` : ''
            const fontWeight = (this.fontWeight === 'bold') ? `font-weight-bold` : ''
            const uppercase = (this.uppercase) ? 'text-uppercase' : ''
            return `${classBase} ${color} ${borderBottom} ${borderColor} ${fontWeight} ${uppercase}`
        },
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : this.item.url
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        hoverClass: function(){
            const classBase = 'app-text-item__hover position-absolute '
            const background = (this.itemHover) ? 'bg-info' : ''
            return classBase + background
        },
        linkStyle: function(){
            return (this.fontWeight === 'semi-bold') ? 'font-weight:500' : null
        },
        id: function(){
            return 'app-text-link-'+utils.uuidv4()
        },
        aspect: function(){
            return (this.siteContent) ? '16:9' : '1:1'
        },    
        /*
        tooltipPosition: function(){
            return (this.SCREEN_SIZE === 7) ? 'left' : 'right'
            // return 'right'
        },
        tooltipConditions: function(){
            return (this.tooltip && this.PLATFORM === 'desktop')
        }
        */
       adsMarker: function(){
        const vueContext = this
        return (utils.isRotator(vueContext) && this.item.url.match(/smi2\./i))
       }
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        },
        mouseEnter: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? true : this.itemHover
        },
        mouseLeave: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? false : this.itemHover
        },
        /*
        tooltipClick: function(){
            const vueContext = this
            utils.safeGoal('TooltipClick', vueContext)
        }
        */
    },  
}
</script>

<style lang="sass">
.app-text-item__hover
    top: 0
    bottom: 0
    left: -1000px
    right: -1000px
    opacity: 0.1
    transition: all 0.1s
.tooltip.b-tooltip
    opacity: 1 !important
    *
        opacity: 1 !important
    .tooltip-inner 
        background-color: #0056b3 !important
    .arrow::before
        border-left-color: #0056b3 !important
        border-right-color: #0056b3 !important
</style>