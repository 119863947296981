<template>
    <b-link 
        :to="linkTo" 
        :href="linkHref" 
        :class="linkClass" 
        :target="target" 
        @click="itemClick"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"        
    >
        <b-aspect :aspect="aspect" :class="classAspect" :style="styleAspect">
            <div class="position-absolute" style="top: 0; bottom: 0; left: 0; right: 0">
                <b-skeleton width="100%" height="100%"></b-skeleton>
            </div>
            <div :class="classImageWrap" :style="styleImageWrap">
                <b-img-lazy :src="item.img" :style="imgStyle"></b-img-lazy> 
            </div>
        </b-aspect>
        <span class="font-weight-bold">
            {{ item.title }}
        </span>
    </b-link>
</template>

<script>
import { mapGetters } from 'vuex'
import { BImgLazy, BAspect, BLink, BSkeleton } from 'bootstrap-vue'
export default {
    components: {
        BImgLazy,
        BAspect,
        BLink, 
        BSkeleton
    },
    data(){
        return {
            classAspect: 'd-flex justify-content-center align-items-start flex-shrink-0 mr-3 mt-1 position-relative',
            classImageWrap: 'rounded overflow-hidden d-flex justify-content-center align-items-center position-absolute',
            styleAspect: 'width: 100px',
            styleImageWrap: 'top:0;bottom:0;left:0;right:0',
            itemHover: false
        }
    },
    props: ['item', 'siteContent', 'max'],
    computed: {
        ...mapGetters(['ACTIVE_NOW', 'PLATFORM']),
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : this.item.url
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        imgStyle: function(){
           const ratio = (this.siteContent) ? '' : 'height:100%; width:auto;'
           const filter = (this.itemHover) ? 'filter: brightness(1.2)' : ''
           return `${ratio} ${filter}`
        },
        linkClass: function(){
            const classBase = 'd-flex align-items-start mb-3 text-decoration-none'
            const textColor = (this.itemHover) ? 'text-primary' : 'text-black'
            return `${classBase} ${textColor}`
        },
        aspect: function(){
            return (this.siteContent) ? '16:9' : '1:1'
        },        
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        },
        mouseEnter: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? true : this.itemHover
        },
        mouseLeave: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? false : this.itemHover
        }        
    },
}
</script>

<style lang="sass" scoped>
span
    font-size: 1rem 
    line-height: 1.4   
    font-weight: 500
    transition: all 0.2s
img
    transition: all 0.2s    
</style>