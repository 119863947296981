<template>
    <b-skeleton class="my-2" height="31px"></b-skeleton>
</template>

<script>
import { BSkeleton } from 'bootstrap-vue'

export default {
    components: {
        BSkeleton
    },        
}
</script>
